:root {
  /* colors */

  /* primary colours */
  --color-purple-heart: #672bc2;
  --color-lavender: #9f69f0;
  --color-turquoise: #2effcd;
  --color-persian-rose: #ef1ea4;

  /* interaction colours */
  --color-success: #14e4b2;
  --color-warning: #efc11e;
  --color-error: #EF1E2B;

  /* secondary colours */
  --color-turquoise-blue: #53e6d7;
  --color-disco-blue: #2ec8ff;
  --color-neon-blue: #5b53ff;
  --color-neon-purple: #c518f0;
  --color-rose: #f043ab;
  --color-mandy: #ef566c;
  --color-orange: #f09456;
  --color-yellow: #ffda00;
  --color-green-yellow: #b8eb2a;
  --color-shamrock: #56f094;
  --color-forest-green: #0fad87;
  --color-ship-cove: #8285a4;
  --color-catskill: #ebeefb;
  --color-catskill-white: #f9faff;

  /* old secondary colours - don't use */
  --color-cool-blue: #1e9def;
  --color-iris-blue: #36a9c2;
  --color-sienna: #e08062;
  --color-maroon: #a74165;
  --color-violet-red: #f0568e;

  /* greys */
  --color-grey-30: #40374f;
  --color-grey-40: #574f63;
  --color-grey-60: #95919c;
  --color-grey-80: #c4c5d2;
  --color-grey-90: #e6e4eb;
  --color-grey-100: #fafbff;
  --color-white: #fff;

  /* greys with transparencies */
  --color-grey-40-transparent: #574f634c;

  /* colors - by function */
  --color-primary: var(--color-purple-heart);
  --color-primary-light: var(--color-lavender);
  --color-primary-contrast: var(--color-white);
  --color-highlight: var(--color-catskill-white);
  --color-background: var(--color-white);
  --color-card-fill: var(--color-catskill-white);
  --color-grey-outline: var(--color-grey-90);
  --color-header: var(--color-grey-30);
  --color-header-secondary: var(--color-grey-40);
  --color-transparent: transparent;
  --color-modal-background: var(--color-grey-90);
  --box-shadow-card: 0px 4px 11px rgba(18, 6, 35, 0.08);
  --box-shadow-card--hover: 0 2px 28px rgba(9, 43, 62, 0.1), 0 10px 10px rgba(9, 43, 62, 0.08);
  --color-app-background: var(--color-catskill-white);
  --color-placeholder-light: #4d574f63;

  /* fonts */
  --font-default: 'Avenir Next', sans-serif;

  /* font sizes */
  --body-text-size: 1.5rem;
  --link-text-size: 1.8rem;
  --mobile-font-size: 1.8rem;
  --desktop-font-size: 1.3rem;

  --button-font-size: 1.3rem;
  --font-size-x-small: 1rem;
  --font-size-small: 1.2rem;
  --font-size-medium: 1.5rem;
  --font-size-heading: 1.8rem;
  --font-size-large: 2rem;
  --font-size-x-large: 2.2rem;
  --font-size-x-x-large: 2.4rem;
  --font-size-xxx-large: 2.8rem;
  --font-size-giant: 3.2rem;

  /* new typology component sizing */
  --font-size-h1: 3.2rem;
  --font-size-h2: 2.6rem;
  --font-size-h3: 2.4rem;
  --font-size-h4: 2.2rem;
  --font-size-h5: 2rem;
  --font-size-h6: 1.8rem;
  --font-size-body: 1.5rem;
  --font-size-link: 1.6rem;

  --font-size-x-large: 3.2rem;
  --font-size-large: 2.8rem;
  --font-size-medium: 1.5rem;
  --font-size-small: 2rem;
  --font-size-x-small: 1.8rem;
  --font-size-xx-small: 1.4rem;
  --font-size-xxx-small: 1.2rem;
  --font-size-body: 1.5rem;

  /* --svg-logo-white: url('../logo.svg');
  --svg-logo-purple: url('../logo-purple.svg'); */

  /* padding */
  --profile-image-left-margin: 20px;
  --page-padding-top: 64px;
  --element-padding-x: 4px;
  --element-margin: 12px;

  --text-max-width: 760px;

  --group-padding-x: 16px;
  --group-padding-y: 16px;
  --section-padding-x: 32px;

  /* z-index */
  --z-index-modal: 2;
  --z-index-nav: 1;

  /* dimensions */
  --profile-image-height: 384px;
  --border-radius: 16px;
  --border-radius-rounded: 290486px;
  --banner-height-desktop: 64px;

  --gap: 32px;
  --min-col-width: 50px;

  --speed: 86ms;
  --mini: 280px;
  --mobile: 375px;
  --mobile-large: 420px;
  --tablet: 769px;
  --desktop: calc(960px + calc(2 * var(--gap)));
  --widescreen: calc(1152px + calc(2 * var(--gap)));
  --fullhd: calc(1344px + calc(2 * var(--gap)));

  $gap: 32px;
  $mini: var(--mini);
  $mobile: var(--mobile);
  $mobile-large: var(--mobile-large);
  $tablet: var(--tablet);
  $desktop: var(--desktop);
  $widescreen: var(--widescreen);
  $fullhd: var(--fullhd);

  --transition: all 0.1s ease-in;
}

@define-mixin color-fill $color {
  background-color: $color !important;
  color: white;
}
@define-mixin color-outline $color {
  background-color: white;
  color: $color;
  border: 1px solid $color;
}
