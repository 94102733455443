.signup,
.login {
  background-color: var(--color-app-background);
  min-height: 100vh;
}

.login-illo {
  display: block;
  margin: auto;
  height: 60vh;
}

.login-logo {
  display: block;
  margin: auto;
  width: 100px;
  padding-bottom: 20px;
}

h1.auth-form__title {
  padding-bottom: 20px;
}

.auth-input {
  margin-bottom: 16px;
}

.auth-input-small {
  position: relative;
  display: flex;
  align-items: center;
}

.auth-input__label {
  position: relative;
  display: block;
}

.auth-input__label-small {
  font-size: 1.5rem;
  color: var(--color-grey-40);
  font-weight: 300;
  margin: 1rem;
  width: 100%;
  text-align: left;
}

.auth-input__label--email {

}

.auth-input__input {
  border: 2px solid var(--color-grey-80);
  border-radius: 35px;
  padding: 20px 32px 20px 50px;
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 1;
  transition: padding 150ms;
  -webkit-appearance: none;
  font-size: 16px;
  transition: border-color 100ms;
}

.auth-input__input-small {
  border-radius: 3.6rem;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 1.5rem;
  z-index: 1;
  transition: padding 150ms;
  -webkit-appearance: none;
  transition: border-color 100ms;
  margin-top: 5px;
  margin-bottom: 1rem;
  color: var(--color-grey-40);
  border: 2px solid var(--color-grey-80);
}
.auth-input__input-small::placeholder {
  color: var(--color-grey-40-transparent);
}
.auth-input__input-small.has-start-icon {
  padding-left: 30px;
}
.auth-input__input-small.has-end-icon {
  padding-right: 38px;
}

.auth-input__input:focus,
.auth-input__input-small:focus {
  outline: none;
  border-color: #2EFFCD;
}

.auth-input--weak-pw.auth-input--weak-pw,
.auth-input--weak-pw:focus.auth-input--weak-pw:focus {
  border-color: #EF1E2B;
}

.auth-input--med-pw.auth-input--med-pw,
.auth-input--med-pw:focus.auth-input--med-pw:focus {
  border-color: #efc11e;
}

.auth-input--strong-pw.auth-input--strong-pw,
.auth-input--strong-pw:focus.auth-input--strong-pw:focus {
  border-color: #2EFFCD;
}

.has-error .auth-input__input .auth-input__input-small {
  border-color: #EF1E2B;
}

.auth-input__floating-label {
  position: absolute;
  left: 50px;
  top: 16px;
  font-size: 12px;
  pointer-events: none;
  z-index: 10;
  opacity: 0;
  transform: translateY(3px);
  transition-property: opacity, transform;
  transition-duration: 150ms;
  color: var(--color-grey-60);
}

.auth-input__icon {
  position: absolute;
  left: 20px;
  z-index: 11;
  top: 21px;
}
.auth-input__icon-small {
  position: absolute;
  left: 20px;
  z-index: 11;
  top: 45px;
}

.filled .auth-input__input {
  padding-top: 30px;
  padding-bottom: 10px;
}

.filled .auth-input__floating-label {
  opacity: 1;
  transform: translateY(0);
}

.auth-input__error,
.auth-form__error {
  padding-left: 22px;
  a {
    color: #E82756;
    text-transform: none;
    font-size: var(--font-size-body);
    letter-spacing: inherit;
    text-decoration: underline;
  }
}

.auth-error--pw-2 {
  color: #efc11e;
}

.auth-form__errors {
  margin-bottom: 24px;
}

.auth-form__error {
  padding-bottom: 24px;
}

.auth-page {
  margin-top: 20px;
}

.auth-page__heading {
  margin-bottom: 16px;
}

.auth-page__subheading {
  margin-bottom: 8px;
}

.auth-page__form {
  margin-bottom: 32px;
  text-align: left;
}

.auth-form__password-suggest {
  background-color: #EBEEFB;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 8px 18px 8px 24px;
  border-radius: 16px;
  border-top-left-radius: 0;
  position: relative;
  display: flex;
  text-align: left;
  flex-direction: column;

  p {
    color: #2B144F;
    margin-bottom: 8px;
    padding-top: 4px;
  }
}

.auth-form__password-suggest.is-snug {
  margin-top: -8px;
}

.auth-form__password-suggest:before {
  content: "";
  width: 33px;
  height: 13px;
  position: absolute;
  left: 0;
  top: -12px;
  background-image: url('../images/wave-corner.svg');
  background-repeat: no-repeat;
  background-position: bottom left;
}

p.auth-form__password-suggest__try {
  padding-right: 8px;
  font-variant-caps: all-small-caps;
  font-variant: small-caps;
  font-weight: 600;
  color: #6B71A4;
}

.auth-page__form h1 {
  text-align: left;
  margin-bottom: 10px;
}

.auth-page__form p {
  text-align: left;
  margin: 0;
}

.auth-page__card {
  display: block;
  &__header {
  display: flex;
  padding: 18px 24px;
    .icon {
      margin-right: 16px;
    }
  }
  &__body {
    padding: 18px 24px;
  }
  .type-paragraph {
    margin-top: 4px;
  }
  .type-h3 {
    font-size: var(--font-size-xx-small);
    margin-bottom: 16px;
  }
  &__list {
    list-style-type: circle;
    padding-left: 20px;
    list-style-position: outside;
    &-item {
      margin-bottom: 8px;
    }
  }
}

.auth-form__row,
.auth-form--login .auth-form__row {
  /* padding-right: 32px; */
}

.auth-page__header {
  padding-bottom: 32px;
}

.auth-page__header p {
  padding-top: 0;
}

.auth-form__row {
  position: relative;
}

.auth-form__show-password {
  z-index: 1;
  position: relative;
  right: 10px;
  top: 10px;
}

.auth-form__toggle-password {
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-form__toggle-password .ic-eye-closed {
  /* top: -2px; */
  position: relative;
}

.auth-form__toggle-password {
  right: 5px;
  bottom: 29px;
  position: absolute;
  background: none;
}

.auth-form__footer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.auth-form__forgot-pw {
  text-align: center;
  margin:1.5rem;
}

.auth-form__remember span {
  margin-left: 8px;
}

.auth-tabs {
  border-bottom: 1px solid #E6E4EB;
  display: flex;
  margin-bottom: 32px;
}

.auth-tabs__tab,
a.auth-tabs__tab {
  height: fit-content;
  font-size: 16px;
  font-variant: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-transform: none;
  padding: 12px;
  text-align: center;
  font-family: var(--font-default);
  color: var(--color-grey-80);
  width: 50%;
  line-height: 1;
  background-color: transparent;
  &:focus {
    outline: 0;
    border-bottom: 3px solid #E6E4EB;
  }
}

.auth-tabs__tab:hover {
  border-bottom: 3px solid #E6E4EB;
}

.auth-tabs__tab.is-active {
  border-bottom: 3px solid #9F69F0;
  color: #32385A;
}

@mixin desktop {
  .auth-page {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

  .auth-page__form {
    width: 40%;
  }

  .auth-page__info {
    width: 40%;
  }

  .auth-tabs {
    margin-bottom: 60px;
  }
}

@mixin until $mobile {
  .auth-page__card__header {
    @mixin flex-column;
    .icon {
      width: 32px;
    }
  }
}